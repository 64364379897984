import React from "react";
import ConferencesPage from "../Conferences";
import "./style.scss";

const HomePage = () => {
  return (
    <div className="home-page-containerr">
      <ConferencesPage />
    </div>
  );
};

export default HomePage;
