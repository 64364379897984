import React from "react";
import Navbar from "../../components/UI/Navbar";
import Slider from "../../components/Slider";
import Gallery from "../../components/UI/Galary";
import Footer from "../../components/UI/Footer";

const HomeR = () => {
  return (
    <div>
     
      <Slider />
      {/* <Gallery /> */}
     <Footer/>
    </div>
  );
};

export default HomeR;
